import React from 'react'
import { Button,Input } from "antd"
import { Rocket, List, Lightbulb, Code, DollarSign } from "lucide-react"
import "./index.css"


export default function InitiatorsLandingPage() {
  return ( <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-16 flex items-center">
        <a className="flex items-center justify-center" href="#" >
          <Rocket className="h-6 w-6 mr-2" />
          <span className="font-bold text-xl">Initiators</span>
        </a>
        <nav className="ml-auto flex gap-4 sm:gap-6">
          <a className="text-sm font-medium hover:underline underline-offset-4" href="#how-it-works">
            How It Works
         </a>
          <a className="text-sm font-medium hover:underline underline-offset-4" href="#whitelist">
            Join Whitelist
         </a>
        </nav>
      </header>
      <main className="flex-1">
        <section className="w-full py-12 md:py-24 lg:py-32 xl:py-48 bg-primary">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <div className="space-y-2">
                <h1 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl lg:text-6xl/none text-white">
                  Welcome to Initiators
                </h1>
                <p className="mx-auto max-w-[700px] text-gray-200 md:text-xl">
                  The Tinder for Startups: Connecting Ideas, Talent, and Investment
                </p>
              </div>
              <div className="space-x-4">
                <Button className="bg-transparent text-white border-white hover:bg-white hover:text-primary"  href="#how-it-works">
                  Learn More
                </Button>
              </div>
            </div>
          </div>
        </section>
        <section id="features" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6">
            <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl text-center mb-8">Our Features</h2>
            <div className="grid gap-10 sm:grid-cols-2 lg:grid-cols-4">
              <div className="flex flex-col items-center text-center">
                <Lightbulb className="h-12 w-12 mb-4 text-primary" />
                <h3 className="text-xl font-bold mb-2">Idea Matching</h3>
                <p className="text-gray-500">Find technical experts to bring your startup idea to life.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <Code className="h-12 w-12 mb-4 text-primary" />
                <h3 className="text-xl font-bold mb-2">Talent Sourcing</h3>
                <p className="text-gray-500">Discover exciting projects that need your technical expertise.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <DollarSign className="h-12 w-12 mb-4 text-primary" />
                <h3 className="text-xl font-bold mb-2">Investment Opportunities</h3>
                <p className="text-gray-500">Connect with promising startups looking for investors.</p>
              </div>
              <div className="flex flex-col items-center text-center">
                <h3 className="text-xl font-bold mb-2">Collaboration Lab</h3>
                <p className="text-gray-500">Build, review, and explore projects in a collaborative environment.</p>
              </div>
            </div>
          </div>
        </section>
        <section id="how-it-works" className="w-full py-12 md:py-24 lg:py-32 bg-gray-100">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">How It Works</h2>
              <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Initiators is the perfect platform for:
              </p>
              <div className="grid gap-6 sm:grid-cols-3 mt-8">
                <div className="flex flex-col items-center text-center">
                  <Lightbulb className="h-12 w-12 mb-4 text-primary" />
                  <h3 className="text-xl font-bold mb-2">Idea Owners</h3>
                  <p className="text-gray-500">If you have a startup idea and need technical expertise to bring it to life.</p>
                </div>
                <div className="flex flex-col items-center text-center">
                  <Code className="h-12 w-12 mb-4 text-primary" />
                  <h3 className="text-xl font-bold mb-2">Technical Experts</h3>
                  <p className="text-gray-500">If you're a skilled developer or engineer looking for exciting projects to work on.</p>
                </div>
                <div className="flex flex-col items-center text-center">
                  <DollarSign className="h-12 w-12 mb-4 text-primary" />
                  <h3 className="text-xl font-bold mb-2">Investors</h3>
                  <p className="text-gray-500">If you're looking to invest in promising startup projects and ideas.</p>
                </div>
              </div>
              <div className="mt-12">
                <h3 className="text-2xl font-bold mb-4">Collaboration Lab</h3>
                <p className="mx-auto max-w-[700px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                  Our unique Collaboration Lab allows you to build your project, collaborate with team members, review other projects, and explore various options in the startup ecosystem. It's the perfect environment to nurture your ideas and turn them into reality.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section id="whitelist" className="w-full py-12 md:py-24 lg:py-32 bg-white">
          <div className="container px-4 md:px-6">
            <div className="flex flex-col items-center space-y-4 text-center">
              <List className="h-12 w-12 text-primary" />
              <h2 className="text-3xl font-bold tracking-tighter sm:text-4xl md:text-5xl">Join Our Whitelist</h2>
              <p className="mx-auto max-w-[600px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
                Be among the first to experience Initiators. Join our whitelist for early access, exclusive updates, and the opportunity to shape the future of startup collaboration.
              </p>
              <div className="w-full max-w-sm space-y-2">
                <form className="flex flex-col gap-2">
                  <Input type="email" placeholder="Your Email" required />
                  <Input placeholder="Your Role (Idea Owner, Technical Expert, Investor)" />
                  <Button className="bg-primary text-white hover:bg-primary/90">
                    Join Whitelist
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500">© 2024 Initiators. All rights reserved.</p>
        <nav className="sm:ml-auto flex gap-4 sm:gap-6">
          <a className="text-xs hover:underline underline-offset-4" href="#">
            Terms of Service
         </a>
          <a className="text-xs hover:underline underline-offset-4" href="#">
            Privacy
         </a>
        </nav>
      </footer>
    </div>
  )
}