import React from 'react';
import InitiatorsLandingPage from './initiators-landing-page';

function App() {
  return (
    <div className="App">
        <InitiatorsLandingPage />
    </div>
  );
}

export default App;
